import SettingsApi from '../../api/SettingsApi/SettingsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    ORDERS_LIST,
    PUSH_ADD_USER_ERROR,
    REMOVE_ADD_USER_ERRORS_LIST,
    SET_ADD_USER_ERRORS_LIST, SET_RATES_LIST, SET_RETURN_LABELS_ERROR_LOG, SET_UPLOADING_FILES_LIST, SET_USER_LIST

} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"
import PermissionsApi from "../../api/SettingsApi/PermissionsApi/PermissionsApi"
import {setAddCustomsDeclarationRule_ValidationErrorsList} from "./customsDeclarationRulesReducer"
import ratesList from "../../../views/pages/settings/settings-content/commonComponents/AddRates/ratesList"
import {getShippingRulesList} from "./shippingRulesReducer"

const initialState = {
    UploadingFilesList: [],
    ReturnLabelsErrorLog: []
}

const uploadingFiles = (state = initialState, action) => {
    switch (action.type) {
        case SET_UPLOADING_FILES_LIST:
            return {
                ...state,
                UploadingFilesList: action.list

            }
        case SET_RETURN_LABELS_ERROR_LOG:
            return {
                ...state,
                ReturnLabelsErrorLog: action.list

            }
        default:
            return state
    }
}

export const setUploadingFilesList = (list) => ({type: SET_UPLOADING_FILES_LIST, list})
export const setReturnLabelsErrorLog = (list) => ({type: SET_RETURN_LABELS_ERROR_LOG, list})

export const getUploadingFilesList = () => (dispatch, getState) => {
    SettingsApi.getUploadingFilesList(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setUploadingFilesList(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getReturnLabelsErrorLog = () => (dispatch, getState) => {
    SettingsApi.getReturnLabelsErrorLog(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setReturnLabelsErrorLog(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}
export default uploadingFiles
